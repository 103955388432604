<template>
  <v-container fluid>
    <CampaignsOverlay :key="'campaignOverlay'+overlayKey" :no-dialog="noDialog" :parent="editedItem"
                      :record="originalItem" :user="user"
                      data-context="golfvereine"></CampaignsOverlay>
    <v-col v-if="error" class="text-xs-center">
      <v-alert
          type="error"
      >{{ error }}
      </v-alert>
    </v-col>
    <v-card v-if="!error">
      <v-card-title>
        <span class="headline">Stammdaten</span>
      </v-card-title>

      <v-card-text>
        <SpielerEditor
            v-if="clubLoaded"
            xl-cols="6"
            headline="Unsere Daten"
            :edited-item="editedItem"
            :list-model="listModel"
            @validate="validate"
            @change="successMsg=null"
            :sublists="sublists" ></SpielerEditor>
        <v-alert v-if="successMsg" type="success">{{ successMsg }}</v-alert>
      </v-card-text>

      <v-card-actions>

        <v-spacer></v-spacer>

        <SaveButton :originalItem="originalItem" :list-model="listModel" :route-model="routeModel" :edited-item="editedItem" :disabled="!valid || loading" :edited-index="0" :edited-item-i-d="user.golfvereines" @success="success" @failed="failed"></SaveButton>

      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
//import model from "@/helpers/model";
import subListHelper from "@/helpers/subListHelper";
import SpielerEditor from "@/components/views/SpielerEditor";
import {HTTP} from "@/axios";
import saveItemHelper from "@/helpers/saveItemHelper";
import SaveButton from "@/components/functions/SaveButton";
import gqlCreator from "@/helpers/gqlCreator";
import editItemHelper from "@/helpers/editItemHelper";
import CampaignsOverlay from "@/components/campaigns/CampaignsOverlay";

export default {
  name: 'Spieler',
  components: {CampaignsOverlay, SpielerEditor,SaveButton},
  data(){
    return {
      clubLoaded: false,
      editedItem: {},
      originalItem: {},
      valid: false,
      error: null,
      user: {},
      successMsg: null,
      overlayKey: 0,
      noDialog: false
    }
  },
  props: {
    isLoggedIn: Boolean
  },
  watch: {
    isLoggedIn() {
      this.$nextTick(this.loadclubId);
    }
  },
  computed: {
    loading() {
      return this.$apollo.loading;
    },
    listModel() {
      return this.routeModel.model;
      //return model['Vereine'].model;
    },
    routeModel() {
      return require("@/config/models/Spieler").default;
      //return model['Vereine'];
    },
    sublists() {
      //let model = this.listModel;
      return subListHelper(this.listModel,this.userRole);
    },
    spielerId(){
      return this.user.spieler;
    },
    userRole(){
      return this.user.role.name;
    }
  },
  created() {
    //get club id
    this.loadSpielerId();
  },
  methods:{
    success() {
      this.successMsg = "Daten erfolgreich gespeichert";
      //uncomment this to stop reopen campaign tasks after success of saving
      //this.noDialog = true;
      this.overlayKey++;
    },
    failed(er){
      this.error = er;
    },
    save(){
      saveItemHelper(this);
    },
    async loadSpielerId() {
      if (this.isLoggedIn) {
        //reset from local storage in case of user switch without reloading
        HTTP.defaults.headers['Authorization'] = localStorage.getItem('token');
        await HTTP.get("/users/me").then(me => {
          this.user = me.data;
          this.$nextTick(() => {
            this.$apollo.queries.originalItem.refetch();
          });
        });
      } else {
        this.user = null;
      }
    },
    validate(state){
       this.valid = state;
    },
  },
  apollo: {
    originalItem: {
      fetchPolicy: 'no-cache',
      error(er) {
        this.failed(er);
      },
      skip() {
        return this.spielerId === '' || this.spielerId === undefined || this.spielerId === null;
      },
      result() {
        this.$nextTick(() => {
          this.$nextTick(() => {
            if (this.editedItem.id === undefined && this.originalItem.id !== undefined) {
              this.editedItem = {...this.originalItem};
            }
            editItemHelper(this.editedItem, this.listModel);
            this.clubLoaded = true;
          });
        });
      },
      query(){
        return gqlCreator.query(this.listModel,this.routeModel['listName'],{
          qVars:'start: $start, limit: $limit, where: $where',
          qSpec:'$start: Int, $limit: Int, $where: JSON'
        });
      },
      variables() {
        return {
          start: 0,
          limit: 1,
          where: {id:this.spielerId}
        }
      },
      update (data) {
        return data[this.routeModel['listName']][0];
      },
    }
  }
};
</script>
