<template>
  <v-container>
    <v-card>
      <v-tabs
          v-model="tabs"
          background-color="accent"
          dark
      >
        <v-tab
            v-for="campaign in campaigns"
            :key="campaign.name"
        >
          {{ campaign.name }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tabs" >
        <v-tab-item v-for="(campaign,cI) in campaigns" :key="campaign.name+'content'">
          <v-card flat>
            <template v-if="typeof texts[campaign.name] === 'string'">
              <!-- stammdaten ändern -->
              <v-card-title>
                <span class="headline">{{ headlines[campaign.name] }}</span>
              </v-card-title>
              <v-card-text class="pre-line">
                {{ texts[campaign.name] }}
              </v-card-text>
              <v-col v-if="error" class="text-xs-center">
                <v-alert
                    type="error"
                >{{ error }}
                </v-alert>
              </v-col>
              <EditForm v-model="tasks[campaign.name]" :model="taskModels[campaign.name]" :scope="campaign.name"
                        @validation="validate"/>
              <SaveButton :disabled="!campaignStates[campaign.name]" :text="btnTxts[campaign.name]"
                          :beforeSave="async ()=>beforeSave(campaign.name,cI)"
                          :update="campaignUpdateObjects[campaign.name]"
                          @success="saved(campaign.name,cI)"></SaveButton>
              <v-btn v-if="tasks[campaign.name]['Aufgaben Panel']" color="secondary" @click="$emit('close')">Daten
                Bearbeiten
              </v-btn>
            </template>
            <template v-else>
              <!-- wysiswyg content -->
              <v-card-text>
                <WysiwygView
                    :campaign="campaign"
                    :campaignUptadeObject="campaignUpdateObjects[campaign.name][0]"
                    :record="record"
                    :wysiwyg-content="texts[campaign.name]"
                    @success="saved(campaign.name,cI)"
                    @close="$emit('close')"
                ></WysiwygView>
              </v-card-text>
            </template>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>

export default {
  name: "CampaignsView",
  components: {
    WysiwygView: () => import('@/components/views/WysiwygView'),
    SaveButton: () => import('@/components/functions/SaveButton'),
    EditForm: () => import('@/components/Forms/EditForm'),
  },
  props: {
    campaigns: {
      type: Array,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    parent: {
      type: Object,
      required: true
    },
    record: {
      type: Object,
      required: false
    }
  },
  watch: {
    campaigns() {
      this.renderCampaignContent();
    }
  },
  created() {
    this.renderCampaignContent();
  },
  methods: {
    renderCampaignContent() {
      if (Array.isArray(this.campaigns)) {
        String.prototype.interpolate = function (params) {
          const names = Object.keys(params);
          const values = Object.values(params);
          return new Function(...names, `return \`${this}\`;`)(...values);
        }

        this.loadedCampaignsCount = this.campaigns.length;

        this.$props.campaigns.forEach(campaign => {
          const moduleName = campaign.settings.modules["Aufgaben Panel"]
              ? 'Aufgaben Panel'
              : campaign.settings.modules["Veranstaltung"]
                  ? 'Veranstaltung'
                  : 'Bewerbungsannahme Bestätigen';
          const cTasks = campaign.settings.modules[moduleName]
              ?
              campaign.settings.modules[moduleName].tasks || campaign.settings.modules[moduleName].text
              :
              null;
          if (cTasks) {
            this.$set(this.headlines, campaign.name, campaign.settings.modules[moduleName].Headline);
            //this.headlines[campaign.name] = campaign.settings.modules["Aufgaben Panel"].Headline;
            this.$set(this.btnTxts, campaign.name, campaign.settings.modules[moduleName].completed);
            this.$set(this.texts, campaign.name, campaign.settings.modules[moduleName].text);

            this.$set(this.campaignUpdateObjects, campaign.name, [{
              ...this.updateProto,
              ...{
                value: {
                  campaignsDone: [campaign.id]
                },
                id: this.parent.id
              }
            }]);
            // this.btnTxts[campaign.name] = campaign.settings.modules["Aufgaben Panel"].completed;
            // this.texts[campaign.name] = campaign.settings.modules["Aufgaben Panel"].text;
            this.$set(this.campaignStates, campaign.name, false);


            this.tasks[campaign.name] = {};
            this.taskModels[campaign.name] = {};
            if (Array.isArray(cTasks)) {
              cTasks.forEach(cTask => {
                this.tasks[campaign.name][cTask.name] = false;
                let taskContent = cTask.content;
                try {
                  taskContent = taskContent.interpolate(this.parent);
                } catch (er) {
                  this.error = 'Interpolation failed: ' + er.message;
                }

                this.taskModels[campaign.name][cTask.name] = {
                  label: cTask.name,
                  type: "checkbox",
                  required: true,
                  name: cTask.name,
                  subText: taskContent,
                  colspan: 12
                };
              });
              //Campaign is Application confirmation and user or club has applications
            } else if (moduleName === 'Bewerbungsannahme Bestätigen' && this.user && Array.isArray(this.user.competitions)) {

              this.updateProto.mutationKey = campaign.dataSource;
              this.updateProto.typeKey = campaign.dataSource.charAt(0).toUpperCase() + campaign.dataSource.slice(1);

              this.$set(this.campaignUpdateObjects, campaign.name, [{
                ...this.updateProto,
                ...{
                  value: {
                    campaignsDone: [campaign.id]
                  },
                  id: this.parent.id
                }
              }]);

              this.user.competitions.forEach(competitions => {
                this.tasks[campaign.name][competitions.name] = null;

                this.taskModels[campaign.name][competitions.name] = {
                  label: competitions.name,
                  type: "select",
                  required: true,
                  rules: "trueOrFalse",

                  options: [

                    {d: 'Bestätigen', v: true},
                    {d: 'Ablehnen', v: false},
                    {d: 'Auswählen', v: null}
                  ],
                  name: competitions.name,
                  colspan: 12
                };
              });
            }
          }
        })
      }
    },
    validate(state, scope) {
      this.campaignStates[scope] = state;
    },
    async beforeSave(name, cI) {
      //add update object of confirmation if confirm campaign
      if (this.campaigns[cI].settings.modules['Bewerbungsannahme Bestätigen']) {
        //bestätigungskampagne
        //let updateCompetitions = [];
        //need to get ids of competitions first...
        Object.entries(this.tasks[name]).map(entry => {
          this.campaignUpdateObjects[name].push({
            mutationKey: 'competition',
            typeKey: 'Competition',
            value: {
              [this.campaigns[cI].dataSource === 'user' ? 'refereeApproved' : 'clubApproved']: entry[1]
            },
            where: {"name": entry[0]}
          });
        });
      }
    },
    saved(name, cI) {
      this.$props.parent.campaignsDone.push(this.campaigns[cI].id);
      this.campaigns.splice(cI, 1);
      this.campaignsDone.push(name);
      if (this.campaignsDone.length === this.loadedCampaignsCount) {
        this.$emit("allDone");
      }
    }
  },
  computed:{
    activeStepIsValid(){
      return this.campaignStates[this.campaigns[this.tabs].name];
    }
  },
  data(){
    return {
      loadedCampaignsCount: 0,
      tabs: null,
      tasks: {},
      error: null,
      taskModels: {},
      headlines: {},
      btnTxts: {},
      texts: {},
      campaignStates: {},
      campaignsDone: [],
      campaignUpdateObjects: {},
      updateProto: {
        typeKey: 'Golfvereine',
        mutationKey: 'golfvereine',
        value: {
          campaignsDone: []
        },
        id: ''
      }
    }
  }
}
</script>
<style>
.pre-line {
  white-space: pre-line;
}
</style>

