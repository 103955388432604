<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-row>
        <v-col cols="12" md="4" xl="2">
          <v-alert v-if="myCampaigns.length>0" color="primary">Es sind Kampagnen vorhanden
            <v-btn
                color="accent"
                dark
                v-bind="attrs"
                v-on="on"
            >
              Kampagnen ansehen
            </v-btn>
          </v-alert>
        </v-col>
      </v-row>
    </template>
    <v-card>
      <v-toolbar
          dark
          color="secondary"
      >
        <v-btn
            icon
            dark
            @click="dialog=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Aktuelle Kampagnen</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
              dark
              text
              @click="closeDialog"
          >
            fertig
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container>
        <CampaignsView :campaigns="myCampaigns" :parent="parent" :record="record" :user="user" @allDone="allDone"
                       @close="closeDialog"/>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import gql from 'graphql-tag';
import CampaignsView from "@/components/campaigns/CampaignsView";


export default {
  components: {
    CampaignsView
  },
  props: {
    "user": Object,
    "parent": Object,
    "dataContext": String,
    "record": Object,
    "noDialog": Boolean
  },
  name: 'CampaignsOverlay',
  data() {
    return {
      campaigns: [],
      myCampaigns: [],
      dialog: false,
      loaded: false
    }
  },
  watch: {
    myCampaigns() {
      this.dialog = this.myCampaigns.length > 0 && this.$props.noDialog !== true;
    }
  },
  computed: {
    doneIds() {
      return this.$props.parent.campaignsDone.map(doneCampaign => {
        return doneCampaign.id || doneCampaign
      });
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    allDone() {
      this.dialog = false;
      this.$apollo.queries.campaigns.refetch();
    }
  },
  apollo: {
    campaigns: {
      fetchPolicy: 'no-cache',
      result() {
        const that = this;
        const isNotInFuture = (sDate) => {
          return new Date() >= Date.parse(sDate);
        }
        const hasActiveWebTask = (campaign) => {
          for (let module in campaign.settings.modules) {
            if (module === 'Aufgaben Panel' || module === 'Veranstaltung' || module === 'Bewerbungsannahme Bestätigen') {
              if (isNotInFuture(campaign.settings.modules[module].start))
                return true
            }
          }
          return false;
        };
        this.myCampaigns = [];
        that.campaigns.forEach((campaign) => {
          if (this.doneIds.indexOf(campaign.id) === -1 && hasActiveWebTask(campaign)) {
            if (!campaign.filterCampaign) {
              this.myCampaigns.push(campaign);
            } else {
              const LOAD_CAMPAIGNFILTER = gql`
              query campaignFilterRecords($where: JSON!){
                ${this.$props.dataContext}s(where:$where){
                  id
                }
              }
              `

              //add contextID to campaign filter
              let targetFilter = {...campaign.filter};
              if (campaign.filter._where._or) {
                campaign.filter._where._or.forEach((x, i) => {
                  targetFilter._where._or[i]["id"] = that.$props.user.golfvereine || that.$props.user.id;//either golfverein or user
                });
              } else {
                targetFilter._where["id"] = that.$props.user.golfvereine || that.$props.user.id;//either golfverein or user
              }


              const aql = {
                query: LOAD_CAMPAIGNFILTER,
                variables: {where: targetFilter._where},
                fetchPolicy: 'no-cache'
              };

              this.$apollo.query(aql).then((r) => {
                if (r.data[that.$props.dataContext + "s"].length !== 0 && that.myCampaigns.indexOf(campaign) === -1) {
                  that.myCampaigns.push(campaign);

                }

              }).catch((error) => {
                // Error
                console.error(error);

                that.error = "Campaign filter konnte nicht geladen werden. Mehr Details in der Fehlerkonsole.";


              });

              /*




              aql = {
              query: LOAD_TEMPLATE,
              variables: { templateID: this.$props.templateID},
              fetchPolicy: 'no-cache'
            };
              *
              *
              *
              * */
            }
          }
        });

      },
      skip() {
        return this.$props.parent.campaignsDone === undefined || this.$props.parent.campaignsUndone.length === 0;
      },

      query() {
        return gql`query ($where: JSON!){
                     campaigns(where: $where){
                        id
                        name
                        settings
                        dataSource
                        filterCampaign
                        filter
                      }
                    }`

      },
      variables() {
        return {
          "where": {
            "id_in": this.$props.parent.campaignsUndone.map(x => x.id || x),
            hasWebTask: true,
            status: 'gestartet'
          }
        }
      },
      update(data) {
        return data['campaigns'];
      }
    }
  }
}
</script>
