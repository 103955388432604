<template>
  <v-row>
    <v-col cols="12" sm="12" :xl="xlCols">
      <EditForm v-if="!readOnly" v-model="editedItem" :edit-item-index="editIndex" :is-in-sublist="false"
                :model="listModel"
                @change="$emit('change')"
                @validation="validate"/>
      <DataView v-if="readOnly" :item="editedItem"/>
    </v-col>
    <v-col v-if="visibleSublists.length > 0" :xl="xlCols" cols="12" sm="12">
      <v-card-title>
        <span>{{ headline }}</span>
      </v-card-title>
      <v-card>
        <v-tabs v-model="tab" background-color="primary" dark>
          <v-tabs-slider color="accent"></v-tabs-slider>
          <v-tab v-for="item in visibleSublists" :key="item.name">
            {{ typeof item.label === "function" ? item.label(editedItem) : item.label }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in visibleSublists" :key="item.name">
            <v-card flat>
              <v-card-text>
                <Sublist :action-create-disabled="readOnly || item.actionCreateDisabled"
                         :action-delete-disabled="readOnly || item.actionDeleteDisabled"
                         :action-edit-disabled="readOnly || item.actionEditDisabled"
                         :listName="item.listName || item.name"
                         :model="item" :parent="editedItem"/>
              </v-card-text>
            </v-card>
          </v-tab-item>

        </v-tabs-items>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>

import EditForm from "@/components/Forms/EditForm";
import Sublist from "@/components/views/Sublist";
import DataView from "@/components/views/DataView";


export default {
  components: {DataView, EditForm, Sublist},
  props: {
    "editedItem": Object,
    "xlCols": [Number,String],
    "listModel": [Object, Array],
    "sublists": [Array],
    "readOnly": Boolean,
    "headline": String,
    "editIndex": Number
  },
  name: 'ClubEditor',
  data() {
    return {
      tab: undefined
    }
  },
  computed: {
    visibleSublists() {
      let vs = [];
      this.$props.sublists.forEach(sl => {
        if (this.$props.listModel[sl.name].condition === undefined || this.$props.listModel[sl.name].condition(this.$props.editedItem))
          vs.push(sl);
      });
      return vs;
    }
  },
  methods: {
    validate(state) {
      //console.log("emitting validate");
      this.$emit("validate", state);
    },
  }
}
</script>
